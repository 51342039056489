* {
  padding: 0;
  margin: 0;
}

.Publikasi {
  background: #1d3b67;
  color: #202c38;
}

.items {
  width: 200px;
  height: 220px;
  background: #d9d9d9;
  border-radius: 5px;
  text-align: center;

  h2 {
    font-weight: 700;
    font-size: 18px;
    margin-top: 2px;
  }

  img {
    width: 100%;
    height: auto;
  }
}

@media only screen and (max-width: 580px) {
  // .bg{
  //   img{
  //     max-height: 150px;
  //     width: 100%;
  //   }
  // }
  .top-title{
    h1{
      font-size: 24px;
    }
  }
}