nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 1em;

  .nav-i {
    font-size: 14px;
    padding: 0 10px;
    font-weight: 500;
  }
}
.header {
  margin-top: 40px;
  width: 100%;
  position: absolute;
  z-index: 1;
}
.logo {
  margin-top: -30px;
  margin-left: -35px;
  width: 100%;
  height: auto;
  object-fit: contain;
  max-width: 200px;
}

.span-logo {
  font-size: 11px;
  color: inherit;
  margin-top: -30px;
  margin-left: 5px;
}
