.bg {
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  background-color: #f2f3f8;
}

.plane-img {
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  border-top-left-radius: 13px;
  border-bottom-left-radius: 13px;
}

.box-auth {
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.25);
  border-radius: 13px;
  background-color: #f2f3f8;
  z-index: 99;
}

@media screen and (max-width: 899px) {
  .plane-img {
    height: 150px;
    border-top-left-radius: 13px;
    border-top-right-radius: 13px;
    border-bottom-left-radius: 0px;
  }
}
