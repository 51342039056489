footer {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #101d37;
  color: #fbf8ef;
  flex-direction: column;
  padding: 20px 0;
}

.logo-container {
  margin: 5px 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

p {
  font-weight: 300;
  font-size: 12px;
  // color: #fbf8ef;
  justify-content: center;
  margin-right: 20px;
}

.icon {
  width: auto;
  height: 20px;
  margin: 0 10px;
}

.bottom {
  background-color: #152b56;
  color: #fbf8ef;

  h4 {
    font-weight: 600;
    letter-spacing: 2px;
    margin-bottom: 10px;
  }

  a {
    color: #fbf8ef;
    font-size: 12px;
    margin-bottom: 3px;
  }

  .navigations {
    margin-top: 30px;
    display: flex;
    flex-direction: column;
    color: #fbf8ef;
  }
  

}

.info {
  text-align: left;
  display: flex;
}

.logo-partner {
  object-fit: contain;
  max-height: 60px;
  padding: 10px;
}