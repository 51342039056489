html {
  scroll-behavior: smooth;
}

.bg {
  background: url(../../imgAVIF/Rectangle1.avif) no-repeat;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.section-top {
  color: #202c38;

  p {
    font-size: 12px;
    font-weight: 400;
    text-align: center;
    letter-spacing: 5px;
    padding-bottom: 5px;
  }

  h1 {
    font-size: 38px;
    font-weight: 900;
    line-height: 110%;
    letter-spacing: 5px;

    span {
      color: #163461;
    }
  }
}

.our-features {
  text-align: center;
  color: white;
  background-color: #23477d;
  h3 {
    font-size: 16px;
  }
  h2 {
    font-size: 32px;
    font-weight: 700;
    letter-spacing: 7px;
    text-align: center;
  }
  p {
    margin: 0;
    padding: 10px 30px 0 30px;
  }
}

.about-anr {
  background-color: #23477d;
  // text-align: center;
  h2 {
    font-size: 32px;
    font-weight: 700;
    letter-spacing: 2px;
    margin-bottom: 5px;
    color: #23477d;
  }
  p {
    color: inherit;
    font-size: 14px;
    border-left: 8px solid #163461;
    padding-left: 10px;
    border-radius: 2px;
    line-height: 175%;
  }
}

.round-right {
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
}
.image {
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

@media screen and (max-width: 1010px) {
  .sm-center {
    text-align: center;
  }
  .sm-reverse {
    display: flex;
    flex-direction: column-reverse !important;
    width: 100%;
  }

  .round-right,
  .round-left {
    border-top-right-radius: 20px;
    border-top-left-radius: 20px;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
}

.round-right {
  border-top-right-radius: 13px;
  border-bottom-right-radius: 13px;
}
.image {
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

@media only screen and (max-width: 580px) {
  .section-top{
    h1{
      font-size: 24px;
      letter-spacing: 3px;
    }
  }

  .about-anr{
    h2{
      font-size: 24px;
    }
    p{
      font-size: 12px;
    }
  }
}