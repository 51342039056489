.Artikel {
  background: #1d3b67;
  color: #202c38;
}

// .Row {
//   display: grid;
//   grid-template-columns: auto auto auto auto;
//   justify-content: space-evenly;
//   row-gap: 75px;
// }

// .pages {
//   display: grid;
// }

.items {
  width: 200px;
  height: 220px;
  background: #d9d9d9;
  border-radius: 5px;

  // padding: 3px;
  // display: flex;
  // flex-direction: column;
  // justify-content: space-between;
  text-align: center;
  h2 {
    // margin-bottom: 25px;
    font-weight: 700;
    font-size: 18px;
    margin-top: 2px;
  }
  img {
    width: 100%;
  }
}
