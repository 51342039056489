.early-warn {
  background-repeat: no-repeat;
  background-position: center;
  object-fit: cover;
  height: 130px;
  width: 100%;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
}

.box {
  box-shadow: 8px 10px 18px rgba(5, 4, 38, 0.503);
  height: fit-content;
  border-radius: 20px;
  background-color: #f9fbff;
  z-index: 999;
}
