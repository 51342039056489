.top-anr {
  color: #202c38;

  p {
    margin: 0;
  }

  h3 {
    text-decoration: underline;
    font-size: 20px;
    font-weight: 600;
    letter-spacing: 5px;
    text-underline-offset: 10px;
    text-decoration-thickness: 1px;
    padding-bottom: 10px;
  }

  h1 {
    font-size: 38px;
    font-weight: 900;
    line-height: 110%;
    letter-spacing: 3px;
    text-align: end;
    // padding-bottom: 4.5em;
    span {
      color: #163461;
    }
  }

  // .con-anr {
  //   margin-right: 0 !important;
  //   padding-right: 0 !important;
  // }
  .grid-text {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    padding-right: 30px;
  }

  .hero-text {
    height: 150px;
    color: #fbf8ef;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    padding: 20px 30px;
    margin: 2px 0;
    border-radius: 20px;
  }
  .radon {
    background-color: #101d37;
  }
  .air {
    background-color: #152b56;
  }
}

.impact-box {
  text-align: center;
  flex-wrap: nowrap !important;
  h3 {
    font-size: 24px;
    font-weight: 700;
    letter-spacing: 5px;
    margin-bottom: 10px;
    text-decoration: underline;
    text-decoration-thickness: 1px;
    text-underline-offset: 10px;
  }
  p {
    color: inherit;
    font-weight: 400;
  }
}

.round-left {
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
}

@media screen and (max-width: 1010px) {
  .grid-text {
    h1 {
      text-align: center;
    }
    align-items: center !important;
    padding-right: 0 !important;
    padding: 20px 0;
  }

  .impact-box {
    flex-wrap: wrap !important;
  }

  .round-right,
  .round-left {
    border-top-right-radius: 20px;
    border-top-left-radius: 20px;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
}

@media screen and (max-width: 580px) {
  .top-anr{
    h1{
      font-size: 32px;
    }
    h3{
      font-size: 16px !important;
    }
    p{
      font-size: 9px;
    }
  }
}