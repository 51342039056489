.our-station {
  h1 {
    font-size: 30px;
    font-weight: 900;
    text-align: center;
    height: fit-content;
    letter-spacing: 5px;
    color: #242a3d;
  }

  .button-con {
    padding: 0 100px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }

  .button {
    width: 200px;
    height: 45px;
    margin: 12px;
  }
}

.graph-container {
  padding: 50px 0;

  .top-graph {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .bottom-graph {
    display: flex;
    column-gap: 20px;

    .graph-parameter {
      .scrollable-button {
        display: flex;
        flex-direction: column;
        align-items: stretch;
        gap: 10px;
        overflow-x: hidden;
        /* Use "auto" to show scrollbar only when necessary */
        overflow-y: auto;
        white-space: nowrap;
        padding-bottom: 10px;
        /* Add some padding to the bottom to prevent cutoff */
        max-height: 400px;

        button {
          cursor: pointer;
          display: flex;
          justify-content: space-between;
          align-items: center;
          background: #163461;
          color: #ffffff;
          border-radius: 10px;
          padding: 10px 4px;
          border: 0;
          min-width: 150px;

          .button-value {
            text-align: left;
            font-weight: 500;
          }

          .button-name {
            text-align: left;
            letter-spacing: 2px;
          }

          .emote {
            padding: 5px;
            justify-content: space-around;
            align-items: center;
          }

          img {
            width: 33px;
            height: auto;
          }

          &:hover {
            background-color: #41659b86;
          }

          .button-container {
            padding-left: 10px;

            .button-name {
              font-size: 12px;
            }

            .button-value {
              font-size: 24px;
            }
          }
        }
      }
    }
  }
}

.chart-wrapper {
  background-color: rgba(255, 255, 255, 0.5);
  border-radius: 10px;
  padding: 20px;
  flex: 1;
  overflow-x: auto;
  overflow-y: hidden;

  .chart {
    width: 720px;
    height: 350px;
  }
}

.graph-time {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  gap: 7px;

  button {
    cursor: pointer;
    font-size: 10px;
    font-style: normal;
    font-weight: 600;
    border: 2px solid #242a3d;
    border-radius: 12px;
    background-color: transparent;
    width: 100px;
    height: 25px;

    &.selected {
      background: #163461;
      color: #ffffff;
      border: none;
    }

    &:hover:not(.selected) {
      background: #163461;
      color: #ffffff;
      border: none;
    }
  }
}

.graph-container {
  display: flex;
  column-gap: 20px;
  flex-direction: column;
  width: 100%;
  margin: 0 auto;
}

.parameter-container {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  row-gap: 10px;
}

.chart-wrapper {
  background-color: rgba(255, 255, 255, 0.5);
  border-radius: 10px;
  padding: 20px;
  flex: 1;
}

.parameter-container button {
  background: #163461;
  color: #ffffff;
  border-radius: 10px;
  padding: 20px 8px;
  border: 0;
}

.station-name p {
  font-style: normal;
  font-weight: 800;
  font-size: 25px;
  text-align: right;
  letter-spacing: 0.02em;
  color: #242a3d;
  margin-bottom: 10px;
}

.chip {
  // min-width: 200px;
  text-align: center;
  width: 100%;
  border-radius: 20px;
  padding: 5px 0;
  color: white;
  font-size: 14px;
  display: flex;
  justify-content: center;

  p {
    font-size: 14px;
    margin: 0;
    padding: 0 5px;
  }

  .ind-icon {
    font-size: 18px;
  }
}

.high {
  background-color: #a3302a;
}

.mid {
  background-color: #ff9519;
}

.low {
  background-color: #4a9e2c;
}


@media only screen and (max-width: 580px) {
  .bottom-graph {
    flex-direction: column;
  }

  .top-graph {
    justify-content: center;
    flex-direction: column-reverse;
    padding-bottom: 10px;
    .graph-time{
      button{

        width: 70px;
      }
    }
    p {
      margin: 0;
      padding-bottom: 10px;
    }
  }

  .graph-parameter {
    padding-bottom: 10px;
    .scrollable-button {
      flex-direction: row !important;
      overflow-y: hidden !important;
      overflow-x: auto !important;
      padding-bottom: 5px;
      button{
        .button-value{
          font-size: 12px !important;
        }
      }
    }
  }
  .chip {
    flex-direction: column-reverse;
    align-items: center;
    padding: 5px 10px;
    border-radius: 10px;

    .ind-icon {
      margin: 5px 0;
      font-size: 30px;
    }

    .range {
      font-size: 10px;
    }
  }

  .box{
    h3{
      font-size: 14px;
    }
    p{
      font-size: 10px;
    }
  }
}