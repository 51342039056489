.top-title {
  text-align: center;
  color: #2a2a3d;
  padding: 10px 20px;

  h1 {
    font-size: 32px;
    font-weight: 800;
    letter-spacing: 10px;
  }
  p {
    margin: 0;
    letter-spacing: 3px;
  }
}

@media screen and (max-width: 700px) {
  .xs-reverse {
    display: flex;
    flex-direction: column-reverse !important;
    width: 100%;
  }
}
