@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap");

* {
  font-family: "Poppins";
}

body {
  background: #fbf8ef;
  margin: 0;
  overflow: overlay;
  a {
    text-decoration: none;
    margin: 0;
    color: #242a3d;
  }
  a:hover {
    color: #2b5aa1;
  }
  color: #202c38;
}

::-webkit-scrollbar {
  width: 10px;
  background: transparent;
  background-color: transparent;
}

::-webkit-scrollbar-track {
  position: fixed;
  background-color: transparent;
}

::-webkit-scrollbar-thumb {
  background-color: #707f9e;
  border-radius: 20px;
  border: 2px solid transparent;
  background-clip: content-box;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #575b85;
}

.app {
  display: flex;
  height: 100vh; 
}

.sidebar {
  width: 250px; 
  transition: width 0.3s; 
}

.sidebar.collapsed {
  width: 80px; 
}

.content {
  flex: 1;
  padding: 20px; 
  overflow-y: auto; 
}